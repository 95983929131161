import * as React from "react"
import { graphql } from "gatsby"
import { Link, useTranslation } from "gatsby-plugin-react-i18next"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => {
  const { t } = useTranslation()

  return (
    <Layout className="bg-reallyDarkBlue">
      <Seo title="404: Not found" />
      <div className="relative max-w-sm md:max-w-xl lg:max-w-3xl mx-auto mb-32">
        <div className="absolute top-0 left-0 z-10 hidden md:block md:text-[18rem] lg:text-[25rem] text-transparent font-bold leading-none bg-clip-text bg-gradient-radial from-darkBlue to-black text-center">
          404
        </div>
        <div className="text-center md:py-10 lg:py-20 p-10 flex flex-col justify-center items-center relative z-20">
          <h1 className="text-white font-bold text-5xl lg:text-6xl">{t("errorTitle")}</h1>
          <p className="text-white text-lg font-light my-16">
            {t("errorDescription")}
          </p>
          <Link to="/" className="w-max block p-2 text-sm text-white bg-purple border border-transparent hover:border-white hover:bg-darkBlue">
            {t("errorCTA")}
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
